import { decamelize } from "humps";

export const convertServerErrorToForm = (serverErrors, setErrorFn) => {
  if (serverErrors?.messages) {
    setErrorFn("form", {
      type: "server",
      message: serverErrors.messages.join("\n"),
    });
  }
  if (serverErrors?.fieldMessages) {
    serverErrors.fieldMessages.map((fieldError: any) => {
      const parseField = (error, parentKey = "") => {
        let key = decamelize(Object.keys(error)[0]);
        if (parentKey) {
          key = `${parentKey}.${key}`;
        }
        const value = Object.values(error)[0] as [string];
        if (Array.isArray(value) && typeof value[0] === "object") {
          value.map((subValue: any) => {
            parseField(subValue, key);
            return null;
          });
        } else {
          setErrorFn(key, {
            type: "server",
            message: value.join("\n"),
          });
        }
      };
      parseField(fieldError);
    });
  }
};
